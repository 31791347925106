<style scoped lang='scss'>

.BoxPadding{
  padding-top: 100px;
  padding-bottom:100px;
}
.indexMainTitle{
  margin-bottom: 25px;
}
.indexMainTitle_second{
  margin-bottom: 42px;
  font-size: $Size22;
  line-height: 32px;
}
@keyframes widthMax
{
  0% {width: 250px;}
  100% {width: 613px;}
}
@-webkit-keyframes widthMax
{
  0% {width: 250px;}
  100% {width: 613px;}
}
@keyframes opacityChange
{
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}
@-webkit-keyframes opacityChange
{
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}
.width{
  width:1400px;
  margin: auto;
  position: relative;
}
.index_banner{
  width:100%;
  min-width:1400px;
  min-height:335.42px;
  position: relative;
  .banner{width:100%;}
  .banner_title{
    text-align: center;
    position: absolute;
  }
  .banner_title1{
    width: 100%;
    // top: 140px;
    color:white;
    height: 80px;
    top: calc(50% - 40px);
    font-size: $Size60;
  }

}
.business{
  min-width:1400px;
  width:100%;
  color: $indexColor222;
  background-color: #fff;
  .business_main_bg{
    width: 100%;
    margin: 2px auto 0;
    display: block;
  }
  .business_main{
    width: 1400px;
    height: 700px;
    border:2px solid #d8e7f7;
    box-sizing: border-box;
    // overflow: hidden;
    .business_main_tipsList{
      height: 70px;
      background-color: #d8e7f7;
      width: 1398px;
      text-align: center;
      padding-right: 2px;
      .business_main_tips{
        width: 33.3333%;
        font-size: 22px;
        line-height: 70px;
        cursor: pointer;
        position: relative;
        .product_line{
          width: 100%;
          height: 4px;
          position: absolute;
          left: 0;
          top: -2px;
          display: none;
        }
      }
      .business_main_tips:nth-child(2){border-left:1px solid #c4d7ea;border-right:1px solid #c4d7ea;}
      .business_main_tips.active{
        background-color: white;
        .product_line{display: block;}
      }
    }
    .business_mainBox{
      .hexin{
        padding: 38px 36px 0;

        .hexin_item{
          width: 315px;
          height: 560px;
          border-radius: 10px;
          // border: 1px solid #c4d7ea;
          img{width: 100%;}

          .hexin_item_box{
            box-sizing: border-box;
            height:332px;
            padding: 25px 28px 18px;
            position: relative;
            -webkit-box-shadow: 0px 0px 25px rgba(0,0,0,0.05) ;
            -moz-box-shadow:  0px 0px 25px rgba(0,0,0,0.05);
            box-shadow:  0px 0px 25px rgba(0,0,0,0.05);

            .title_hexin{ font-size: $Size20; }
            .line-hexin{width: 100%;height: 1px;background-color: #bbb;margin: 14px 0;}
            .ms-hexin{ font-size: $Size16;color: $footerColor666;line-height: 28px;}
            .func-hexin{
              .func-hx-item{margin-bottom: 8px;color: #666;}
              .func-hx-item > div{
                max-width: 238px;
                line-height: 24px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
                }
              .func-hx-item > div:nth-child(3){margin-left: 21px;}
              img{width: 11px;height: 10px;margin-right: 10px;}
            }
            .char-hexin{
              margin-bottom: 10px;
              color: #666;
              .td-hexin{margin: 18px auto 8px 0;}
            }
            .hexin_item-more{
              color: #217abc;
              position: absolute;
              font-size:16px;
              bottom: 20px;
              left: 28px;
              cursor: pointer;
              span{display: none;}
            }
          }
          .hexin_item_box:hover{
            -webkit-box-shadow: 0px 0px 25px rgba(0,0,0,0.15) ;
            -moz-box-shadow:  0px 0px 25px rgba(0,0,0,0.15);
            box-shadow:  0px 0px 25px rgba(0,0,0,0.15);

            .hexin_item-more{
              span{display: inline-block;}
            }
          }
        }
      }
    }
  }

}
.agriculture{
  min-width:1400px;
  width:100%;
  background-color: #f4f4f4;
  color: $indexColor222;
  .agriculture_title{
    font-size: $Size50;
  }
  .agriculture_product{ 
    background-color: #f4f4f4;
    .sensorDetailImgHeight{
      height: 643px;
      .sensorList{
        width: 190px;
        img{ width: 100%; }
        .sanjiao{border-right: 13px solid #f4f4f4;}
        .sensor-title{top:110px;font-size: 20px;}
      }
    }    
    .sensorDetailImg{margin-left: 17px;width: 1192px;height: 643px;}
    .business_main_bg{margin: 0 auto;}
  }
  // 动态切换的样式
  .agriculture_productItem.active{
    width: 613px;
    .agriculture_productName{
      padding: 60px 90px 0 35px;
    }
    .agriculture_productItemModal{
      animation:widthMax 0.6s;
      -moz-animation:widthMax 0.6s; /* Firefox */
      -webkit-animation:widthMax 0.6s; /* Safari and Chrome */
      -o-animation:widthMax 0.6s; /* Opera */
      animation-iteration-count:1;/*动画只执行一次*/
      -moz-animation-iteration-count:1;
      -webkit-animation-iteration-count:1;
      -o-animation-iteration-count:1;
      animation-fill-mode:forwards;
      transition-timing-function:ease-in-out;
      -moz-transition-timing-function:ease-in-out;
      -webkit-transition-timing-function:ease-in-out;
      -o-transition-timing-function:ease-in-out;
    }
    .agricultureItem-ts{display: block !important;}
    img.jiantou{ display: none !important;}
  }

  .agriculture_productItem{
    width: 250px;
    height: 662px;
    background-color: #f4f4f4;
    position: relative;
    img{ width: 613px;}
    img.jiantou{
      width: 20px;
      height: 14px;
      position: absolute;
      right: 15px;
      top: calc(50% - 7px);
    }
    .agriculture_productName{
      width: 100%;
      padding: 60px 0px 0 35px;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      .agricultureItem-t{
        font-size: $Size26;
        margin-bottom: 20px;
      }
      .agricultureItem-ts{
        font-size: $Size15;
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: none;
      }
    }
  }
  .agriculture_productItem .agriculture_productItemModal{
    height: 662px;
    overflow: hidden;
  }
}
.sensor{
  background-color: #ffffff;
  width:100%;
  min-width: 1400px;
  .sensorList{
    position: relative;
    cursor: pointer;
    .sensor-title{
      font-size: $Size24;
      color: white;
      width:100%;
      position: absolute;
      top: 60px;
    }
    // 三角形
    .sanjiao{
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 13px solid white;
      border-bottom: 13px solid transparent;
      border-left: 13px solid transparent;
      position: absolute;
      top: calc(50% - 13px);
      right: 0;
    }
  }
  .sensorDetailImgHeight{
    height: 758px;
  }
  .sensorDetailImg{
    width: 1140px;
    height: 758px;
    position: relative;
  }
  .sensorDetailImg.active{
    animation:opacityChange .5s;
    -moz-animation:opacityChange .5s; /* Firefox */
    -webkit-animation:opacityChange .5s; /* Safari and Chrome */
    -o-animation:opacityChange .5s; /* Opera */
    animation-iteration-count:1;/*动画只执行一次*/
    -moz-animation-iteration-count:1;
    -webkit-animation-iteration-count:1;
    -o-animation-iteration-count:1;
    animation-fill-mode:forwards;
    transition-timing-function:ease-in-out;
    -moz-transition-timing-function:ease-in-out;
    -webkit-transition-timing-function:ease-in-out;
    -o-transition-timing-function:ease-in-out;
  }

}
.changeModal{
  width: 200px;
  height: 50px;
  text-align: center;
  border-radius: 8px;
  color: white;
  line-height: 50px;
  font-size: $Size16;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 10px);
  background-color: rgba($color: #000000, $alpha: 0.8);
}

</style>

<template>
  <div id="business">
    <div class="index_banner" id="Product">
      <img class="banner" src="@assets/images/business/banner.jpg" alt="">
      <div class="banner_title banner_title1 wow fadeInUp">科技改变世界 &nbsp;创造幸福社会</div>
    </div>
    <div class="business BoxPadding" ref="product">
      <div class="business_title indexMainTitle textCenter weight wow fadeInUp" data-wow-delay="0.3s">嵌入式智慧产品解决方案</div>
      <div class="indexMainTitle_second textCenter wow fadeInUp" data-wow-delay="0.5s">
        根据需求,提供最合适的SoC，并结合SoC提供产品的整套解决方案。包括图形图像类产品、游戏周边产品等等。
        <br>
        因使用自主开发的系统平台和游戏引擎等，我们的方案拥有系统优秀、稳定性好、启动速度快的优势。
      </div>
      <div class="business_product width wow fadeInUp" data-wow-delay="0.7s">
        <div class="business_main">
          <div class="acea-row row-between-wrapper business_main_tipsList">
            <div class="business_main_tips weight" :class="[index==business_main_Index?'active':'']"
              v-for="(item, index) in business_mainList" :key="index"
              @click="businessProductChange(index)"
            >
              {{item}}
              <div class="product_line"></div>
            </div>
          </div>
          <div class="business_mainBox">
            <div v-if="business_main_Index==0">
              <el-tabs class="qrs" v-model="activeName1" type="card" @tab-click="handleClick1">
                <el-tab-pane :name="item.label" v-for="(item, index) in business_main_products" :key="index">
                  <span slot="label">
                    <span class="span-box">
                      <img class="el-icon-question" :src="activeName1!=item.label?item.icon1:item.icon2" alt="">
                      <span>{{item.label}}</span>
                    </span>
                    <div v-if="activeName1==item.label" class="sanjiao"></div>
                  </span>                  
                  <img class="business_main_bg" :src="item.img" alt="">
                </el-tab-pane>
              </el-tabs>
            </div>
            <div v-if="business_main_Index==1">              
              <el-tabs class="qrs" v-model="activeName2" type="card" @tab-click="handleClick2">
                <el-tab-pane :name="item.label" v-for="(item, index) in business_main_yxzb" :key="index">
                  <span slot="label">
                    <span class="span-box">
                      <img class="el-icon-question" :src="activeName2!=item.label?item.icon1:item.icon2" alt="">
                      <span>{{item.label}}</span>
                    </span>
                    <div v-if="activeName2==item.label" class="sanjiao"></div>
                  </span>                  
                  <img class="business_main_bg" :src="item.img" alt="">
                </el-tab-pane>
              </el-tabs>
            </div>
            <div v-if="business_main_Index==2">
              <div class="hexin acea-row row-between">
                <div class="hexin_item" v-for="(item, index) in business_main_hxjs" :key="index">
                  <img :src="item.img" alt="" srcset="">
                  <div class="hexin_item_box">
                    <div class="title_hexin weight">{{item.title}}</div>
                    <div class="line-hexin"></div>
                    <div v-if="item.introduce" class="ms-hexin">{{item.introduce}}</div>
                    <div v-if="item.func.length>1" class="func-hexin">
                      <div class="func-hx-item acea-row row-left row-middle" v-for="(itemf, indexf) in item.func" :key="indexf">
                        <img src="@assets/images/business/funcIcon.png" alt="" srcset="">
                        <div v-for="(itemff, indexff) in itemf" :key="indexff">
                          {{itemff}}
                        </div>
                      </div>
                    </div>
                    <div v-if="item.char.length>1" class="char-hexin func-hexin">
                      <div class="td-hexin"></div>
                      <div class="func-hx-item acea-row row-left row-middle" v-for="(itemC, indexC) in item.char" :key="indexC">
                        <img src="@assets/images/business/funcIcon.png" alt="" srcset="">
                        <div>{{itemC}}</div>
                      </div>
                    </div>
                    <router-link class="hexin_item-more" target="_blank" :to="{path:'/'+item.detailUrl}">查看详情&nbsp;&nbsp;<span>→</span></router-link>
                  </div>

                </div>
                
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="agriculture textCenter BoxPadding" ref="agriculture" id="agriculture">
      <div class="agriculture_title indexMainTitle weight wow fadeInUp" data-wow-delay="0.2s">智慧农业</div>
      <div class="indexMainTitle_second textCenter wow fadeInUp" data-wow-delay="0.5s">
        通过传感器采集温室内温湿度等环境信息，经由无线信号收发模块传输数据，并对数据进行分析处理，以实现对大棚内环境的远程控制
      </div>
      <div class="agriculture_product width sensor wow fadeInUp" data-wow-delay="0.5s">
        <el-tabs class="qrs" type="card" v-model="agricultureActiveName" @tab-click="agricultureClick">
          <el-tab-pane name="方案优势">
            <span slot="label">
              <span class="span-box">
                <img class="el-icon-question" :src="agricultureActiveName=='方案优势'?ysIcon_active:ysIcon" alt="">
                <span>方案优势</span>
              </span>
              <div v-if="agricultureActiveName=='方案优势'" class="sanjiao"></div>
            </span>                  
            <div class="sensor-tab width acea-row row-left wow fadeInUp" data-wow-delay="0.3s">
              <div class="sensorDetailImgHeight acea-row row-column-between">
                <div class="sensorList" v-for="(item, index) in faysList" :key="index" @click="faysChange(index)">
                  <img :src="item.img" alt="图片展示">
                  <div class="sensor-title textCenter">{{item.title}}</div>
                  <div class="sanjiao" v-if="faysIndex==index"></div>
                </div>
              </div>
              <img class="sensorDetailImg" :class="{active:isActive_fays}" :src="faysList[faysIndex].detailImg" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane name="方案场景">
            <span slot="label">
              <span class="span-box">
                <img class="el-icon-question" :src="agricultureActiveName=='方案场景'?cjIcon_active:cjIcon" alt="">
                <span>方案场景</span>
              </span>
              <div v-if="agricultureActiveName=='方案场景'" class="sanjiao"></div>
            </span>                  
            <img class="business_main_bg wow fadeInUp" data-wow-delay="0.3s" src="@assets/images/business/agriculture/facjBg.jpg" alt="">
          </el-tab-pane>
          <el-tab-pane name="方案原理">
            <span slot="label">
              <span class="span-box">
                <img class="el-icon-question" :src="agricultureActiveName=='方案原理'?ylIcon_active:ylIcon" alt="">
                <span>方案原理</span>
              </span>
              <div v-if="agricultureActiveName=='方案原理'" class="sanjiao"></div>
            </span>  
            <div class="agriculture_product width acea-row row-between-wrapper wow fadeInUp" data-wow-delay="0.3s">
              <div class="agriculture_productItem" :class="[index==changeIndex?'active':'']"
                v-for="(item, index) in agricultureProductList" :key="index"
                @mouseenter="agricultureChange(index)"
              >
                <div class="agriculture_productItemModal">
                  <img v-lazy="item.img" alt="图片展示">
                  <img class="jiantou" src="@assets/images/business/jiantou.png" alt="">
                  <div class="agriculture_productName textLeft">
                    <div class="agricultureItem-t">{{item.title}}</div>
                    <div class="agricultureItem-ts">{{item.explain}}</div>
                  </div>
                </div>
              </div>
            </div>               
          </el-tab-pane>
        </el-tabs>
      </div>
      
    </div>
    <div class="sensor textCenter BoxPadding" ref="sensor">
      <div class="sensor_title indexMainTitle weight wow fadeInUp" data-wow-delay="0.2s">智慧传感器通讯产品</div>
      <div class="indexMainTitle_second textCenter wow fadeInUp" data-wow-delay="0.5s">
        使用定制芯片和专用模组，提供相应的解决方案，以实现智能配电、全屋智能等。
      </div>
      <div class="sensor-tab width acea-row row-between-wrapper wow fadeInUp" data-wow-delay="0.8s">
        <div class="sensorDetailImgHeight acea-row row-column-between">
          <div class="sensorList" v-for="(item, index) in sensorList" :key="index" @click="sensorChange(index)">
            <img :src="item.img" alt="图片展示">
            <div class="sensor-title textCenter">{{item.title}}</div>
            <div class="sanjiao" v-if="sensorIndex==index"></div>
          </div>
        </div>
        <img class="sensorDetailImg" :class="{active:isActive_sensor}" :src="sensorList[sensorIndex].detailImg" alt="">
      </div>

    </div>
    <!-- 提示弹窗 -->
      <div v-if="changeModal" class="changeModal">切换过快，请稍后重试!</div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      type: '',// 当前页面的参数
      changeIndex: 0,
      // 嵌入式智慧产品解决方案--大分类下标标识
      business_mainList:[
        '嵌入式解决方案','游戏周边产品解决方案','核心技术'
      ],
      // 嵌入式智慧产品解决方案----具体内容1
      activeName1: 'ODM软件开发',  
      business_main_Index:0,
      business_main_products:[
        {label:'ODM软件开发',icon1: require('../assets/images/business/odm_rj.png'),icon2: require('../assets/images/business/odm_rj_active.png'),img:require('../assets/images/business/odm_rjBg.png')},
        {label:'ODM硬件设计',icon1: require('../assets/images/business/odm_yj.png'),icon2: require('../assets/images/business/odm_yj_active.png'),img:require('../assets/images/business/odm_yjBg.png')},
        {label:'ODM结构设计',icon1: require('../assets/images/business/odm_jg.png'),icon2: require('../assets/images/business/odm_jg_active.png'),img:require('../assets/images/business/odm_jgBg.png')},
        {label:'ODM生产制造',icon1: require('../assets/images/business/odm_sc.png'),icon2: require('../assets/images/business/odm_sc_active.png'),img:require('../assets/images/business/odm_scBg.png')},
      ],
      activeName2: '自主设计外观和结构',
      business_main_yxzb:[
        {label:'自主设计外观和结构',icon1: require('../assets/images/business/zizhusj.png'),icon2: require('../assets/images/business/zizhusj_active.png'),img:require('../assets/images/business/zizhusjBg.png')},
        {label:'提供单片机嵌入式解决方案',icon1: require('../assets/images/business/danpianji.png'),icon2: require('../assets/images/business/danpianji_active.png'),img:require('../assets/images/business/danpianjiBg.png')},
      ],
      business_main_hxjs:[
        {
          detailUrl:'OS_solutions',
          img:require('../assets/images/business/OS_solutions.png'),
          title:'嵌入式操作系统',
          introduce:'我司独自开发的控制、管理嵌入式系统的平台VividOS。通过Layer结构，可管理产品开发成果的平台，在缩短开发周期的同时提高了产品质量。主要面向游戏机、玩具、家电等产品领域进行了优化。',
          func:[],//功能
          char:[],//特点
        },
        {
          detailUrl:'image_recognition',
          img:require('../assets/images/business/image_recognition.png'),
          title:'图像识别',
          introduce:'',
          func:[
            ['使用KPU（知识处理单元）','提供对象识别模型'],
            ['可以识别20种物体','识别准确率约90%~95%'],
            ['配备实时操作系统']
          ],//功能
          char:[],//特点
        },
        {
          detailUrl:'video_processing',
          img:require('../assets/images/business/video_processing.png'),
          title:'视频处理',
          introduce:'旨在提供业界最高品质的视频图文产品平台，在图像和视频编解码等硬件相关部分的开发当面积累了大量的技术诀窍，为各类客户的稳定应用开发做出了贡献。',
          func:[],//功能
          char:[],//特点
        },
        {
          detailUrl:'speech_recognition',
          img:require('../assets/images/business/speech_recognition.png'),
          title:'语音识别',
          introduce:'我司语音识别解决方案是关键字识别型，聚焦于家电产品、玩具等利用领域。',
          func:[],//功能
          char:['能够本地对应','响应时间实现在500ms以内','搭载RTOS'],//特点
        }
      ],
      // 智慧农业
      agricultureActiveName:'方案优势',      
      ysIcon:require('../assets/images/business/agriculture/ys.png'),
      ysIcon_active:require('../assets/images/business/agriculture/ys_active.png'),
      cjIcon:require('../assets/images/business/agriculture/cj.png'),
      cjIcon_active:require('../assets/images/business/agriculture/cj_active.png'),
      ylIcon:require('../assets/images/business/agriculture/yl.png'),
      ylIcon_active:require('../assets/images/business/agriculture/yl_active.png'),
      // 方案优势默认下表
      faysIndex:0,
      isActive_fays:false,
      faysList:[
        {
          title: '生长预测模型',
          img: require('../assets/images/business/agriculture/ys_szyc.png'),
          detailImg: require('../assets/images/business/agriculture/ys_szyc_main.jpg'),
        },
        {
          title: '灵活协作管理',
          img: require('../assets/images/business/agriculture/ys_lhxz.png'),
          detailImg: require('../assets/images/business/agriculture/ys_lhxz_main.jpg'),
        },
        {
          title: '超低功耗运行',
          img: require('../assets/images/business/agriculture/ys_cdgh.png'),
          detailImg: require('../assets/images/business/agriculture/ys_cdgh_main.jpg'),
        },
        {
          title: '存储安全可靠',
          img: require('../assets/images/business/agriculture/ys_ccaq.png'),
          detailImg: require('../assets/images/business/agriculture/ys_ccaq_main.jpg'),
        }
      ],
      // 方案原理---数据
      agricultureProductList:[
        {
          title: '传感器数据收集',
          img: require('../assets/images/business/Sensor-collection.jpg'),
          explain: '公司开发有co2传感器、温湿度传感器、光照传感器、水质传感器等。对农业环境进行全面检测收集。',
        },
        {
          title: '大数据分析',
          img: require('../assets/images/business/Big-data-analysis.jpg'),
          explain: '公司积累多年的农业大数据库，可以对作物生长过程中的数据变化进行动态监测干预，提高农作物品质，降低成本。',
        },
        {
          title: '机械自动化',
          img: require('../assets/images/business/Automation.jpg'),
          explain: '自动灌溉、补光、养分补充等农业自动化控制设备。',
        },
        {
          title: '数据可视化',
          img: require('../assets/images/business/Data-visualization.jpg'),
          explain: '可将实时收集的农业环境数据通过云端处理并推送至用户界面，提供作业参考及数据分享。',
        }
      ],
      // 传感器通讯产品
      sensorIndex: 0,
      sensorList:[
        {
          title: 'NBloT',
          img: require('../assets/images/business/NBIoT.jpg'),
          detailImg: require('../assets/images/business/NBloT_main.jpg'),
        },
        {
          title: '智能断路器',
          img: require('../assets/images/business/Intelligent-circuit-breaker.jpg'),
          detailImg: require('../assets/images/business/Intelligent-circuit-breaker_main.jpg'),
        },
        {
          title: '智能照明',
          img: require('../assets/images/business/Intelligent-lighting.jpg'),
          detailImg: require('../assets/images/business/Intelligent lighting_main.jpg'),
        }
      ],
      isChanged:false,// 判断是否还在切换中
      isActive_sensor:false,
      changeModal:false,
    }
  },
  computed: {},
  created () {},
  watch: {
    $route(){
      let _that = this;
      if( _that.$route.query.type ){
        let index = _that.$route.query.type.indexOf('aifrutech');
        let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({ top: offetTop,left: 0,behavior: 'smooth' });
      }else{
        _that.type = ''
      }
    },
  },
  mounted(){
    window.scrollTo({top: 0,left: 0});
    new this.$WOW.WOW({'data-wow-duration':'1s'}).init();
    let _that = this;
    if(_that.$route.query.type){
      let index = _that.$route.query.type.indexOf('aifrutech');
      let link = index<0?_that.$route.query.type:_that.$route.query.type.substr(0,index);
      setTimeout(function(){
        let offetTop = _that.$refs[link].offsetTop-66;
        window.scrollTo({top: offetTop,left: 0,behavior: 'smooth'});
      },400)
    }
  },
  methods: {
    // 智慧农业-- 三个标题切换
    agricultureClick(tab, event){
      this.agricultureActiveName = tab.name;
    },
    // 智慧农业--方案优势的切换
    faysChange(index){
      let _that = this;
      if(_that.isChanged){
        _that.changeModal = true;
        setTimeout(function(){_that.changeModal = false;},800)
        return false
      }
      _that.isActive_fays = true;
      _that.isChanged = true;
      _that.faysIndex = index; 
      setTimeout(function(){
        _that.isActive_fays = false;
        _that.isChanged = false;
      },500)
    },
    // 智慧农业--百叶窗切换
    agricultureChange(index){
      this.changeIndex = index;
    },
    // 嵌入式智慧产品解决方案--切换
    businessProductChange(index){
      this.business_main_Index = index;
    },
    // 嵌入式智慧产品解决方案--具体内容1
    handleClick1(tab, event) {
      // console.log(tab, event);
      this.activeName1 = tab.name;
    },
    // 游戏周边产品--具体内容1
    handleClick2(tab, event) {
      // console.log(tab, event);
      this.activeName2 = tab.name;
    },
    // 智慧传感器通讯产品 ---切换
    sensorChange(index) {
      let _that = this;
      if(_that.isChanged){
        _that.changeModal = true;
        setTimeout(function(){_that.changeModal = false;},800)
        return false
      }
      _that.isActive_sensor = true;
      _that.isChanged = true;
      _that.sensorIndex = index;
      setTimeout(function(){
        _that.isActive_sensor = false;
        _that.isChanged = false;
      },500)
    },

  },

}

</script>
